import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "623px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: true,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const news = document.getElementById("news");
if (news) {
    const newsSplide = splide(news, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    newsSplide.mount();
}

const covenants = document.getElementById("covenants");
if (covenants) {
    const covenantSplide = splide(covenants, {
        perPage: 5,
        // breakpoints: {
        //     1200: {
        //         perPage: 3,
        //         arrows: false,
        //         pagination: true,
        //     },
        //     600: {
        //         perPage: 1,
        //     },
        // },
    });
    covenantSplide.mount();
}

const specialty = document.getElementById("specialties-splide");
if (specialty) {
    const specialtySplide = splide(specialty, {
        perPage: 4,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        },
    });
    specialtySplide.mount();
}
const blog = document.getElementById("blog");
if (blog) {
    const blogSplide = customSplide(blog, {
        type: "slide",
        rewind: true,
        gap: "1rem",
        lazyLoad: "sequential",
        perPage: 3,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: !0
            },
            600: {
                perPage: 1
            }
        }
    });
    blogSplide.mount();
}